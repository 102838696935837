
import type { PropType } from 'vue'
import { defineComponent, ref, watch, onMounted, onBeforeUnmount } from 'vue'
import NotificationsTable from '@/components/pages/admin/personal/notifications/NotificationsTable.vue'
import NotificationsExpansionItem from '@/components/pages/admin/personal/notifications/NotificationsExpansionItem.vue'
import { useBottomBar } from '@/compositions/bottomBar'
import type { TableHeaders } from '@/definitions/shared/types'
import type { NotificationType } from '@/definitions/admin/notifications/types'

export default defineComponent({
  components: {
    NotificationsTable,
    NotificationsExpansionItem,
  },
  props: {
    notificationsSms: {
      type: Boolean,
    },
    notifications: {
      type: Array as PropType<NotificationType[]>,
      default: () => [],
    },
    checkboxes: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { provideData, getDiscardFunc } = useBottomBar()
    const tableHeaders = ref<TableHeaders[]>(
      props.notificationsSms
        ? [
            { text: 'Notification', value: 'notification-slot', hideSortable: true },
            { text: 'Email', value: 'email-slot', width: '80px', hideSortable: true, class: 'text-center' },
            { text: 'SMS', value: 'sms-slot', width: '80px', hideSortable: true, class: 'text-center' },
          ]
        : [
            { text: 'Notification', value: 'notification-slot', hideSortable: true },
            { text: 'Email', value: 'email-slot', width: '80px', hideSortable: true, class: 'text-center' },
          ]
    )
    const tableItems = ref(props.notifications)

    const defaultItems = tableItems.value

    watch(() => tableItems.value, (newValue) => {
      newValue !== defaultItems ? provideData(1) : getDiscardFunc.value()
    })

    const restoreEnabled = () => {
      tableItems.value = defaultItems
    }

    onMounted(() => {
      provideData(0, restoreEnabled)
    })

    onBeforeUnmount(() => {
      getDiscardFunc.value()
    })

    return {
      tableHeaders,
      tableItems,
    }
  },
})
