
import type { PropType } from 'vue'
import { defineComponent, ref, toRefs } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TmTooltip,
    TmButton,
  },
  props: {
    modelValue: {
      type: [String, Object, Boolean, Array],
      required: true,
    },
    options: {
      type: Array as PropType<string[]>,
    },
    type: {
      type: String as PropType<'tags' | 'select' | 'toggle'>,
    },
    label: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    placeholderBtn: {
      type: String,
      default: 'Add',
    },
    placeholder: {
      type: String,
    },
  },
  setup(props) {
    const { modelValue } = toRefs(props)

    const typeValue = ref(modelValue.value)
    const showPlaceholderBtn = ref(props.modelValue)

    return {
      typeValue,
      showPlaceholderBtn,
    }
  },
})
