
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import NotificationsGeneral from '@/components/pages/admin/personal/notifications/NotificationsGeneral.vue'
import NotificationsNotifications from '@/components/pages/admin/personal/notifications/NotificationsNotifications.vue'

export default defineComponent({
  name: 'PersonalNotifications',
  components: {
    PageContent,
    NotificationsGeneral,
    NotificationsNotifications,
  },
  props: {
    menuTitle: {
      type: String,
    },
  },
})
