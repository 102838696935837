import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cb69d51"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip-wrap" }
const _hoisted_2 = { class: "text-center lh-20" }
const _hoisted_3 = { class: "d-flex align-center justify-center" }
const _hoisted_4 = { class: "d-flex align-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    "hide-actions": "",
    "hide-phantom-col": "",
    "hide-footer": "",
    "hide-pagination": "",
    items: _ctx.items
  }, {
    "body-cell-notification-slot": _withCtx((props) => [
      _createTextVNode(_toDisplayString(props.row.label) + " ", 1),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_icon, {
          name: "tmi-help-outline",
          class: "light--text pointer",
          size: "small"
        }),
        _createVNode(_component_tm_tooltip, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(props.row.tooltip), 1)
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    "body-cell-email-slot": _withCtx((props) => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_tm_field, {
          modelValue: props.row.emailValue,
          "onUpdate:modelValue": [($event: any) => ((props.row.emailValue) = $event), _ctx.checkboxChange],
          type: "checkbox"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ])
    ]),
    "body-cell-sms-slot": _withCtx((props) => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_tm_field, {
          modelValue: props.row.smsValue,
          "onUpdate:modelValue": [($event: any) => ((props.row.smsValue) = $event), _ctx.checkboxChange],
          type: "checkbox"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["items"]))
}