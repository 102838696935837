import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications_table = _resolveComponent("notifications-table")!
  const _component_notifications_expansion_item = _resolveComponent("notifications-expansion-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_notifications_table, {
      headers: _ctx.tableHeaders,
      items: _ctx.tableItems,
      "show-selection": false,
      "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => (_ctx.tableItems = $event))
    }, null, 8, ["headers", "items"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkboxes, (line) => {
      return (_openBlock(), _createBlock(_component_notifications_expansion_item, {
        key: line.name,
        modelValue: line.value,
        "onUpdate:modelValue": ($event: any) => ((line.value) = $event),
        options: line.options,
        label: line.label,
        tooltip: line.tooltip,
        type: line.type,
        "placeholder-btn": line.placeholderBtn,
        placeholder: line.placeholder
      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "tooltip", "type", "placeholder-btn", "placeholder"]))
    }), 128))
  ]))
}