
import { defineComponent, ref } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    TmTable,
    TmTooltip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:items'],
  setup(props, context) {
    const itemsValue = ref(props.items)
    const checkboxChange = () => {
      context.emit('update:items', itemsValue.value)
    }
    return {
      checkboxChange,
    }
  },
})
