
import { defineComponent, ref } from 'vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import TmChip from '@/components/shared/TmChip.vue'
import NotificationsExpansion from '@/components/pages/admin/personal/notifications/NotificationsExpansion.vue'
import { notificationSections } from '@/definitions/admin/notifications/data'

export default defineComponent({
  components: {
    InfoCard,
    TmExpansion,
    TmChip,
    NotificationsExpansion,
  },
  setup() {
    const sections = ref(notificationSections)

    return {
      sections,
    }
  },
})
