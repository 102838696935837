import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications_general = _resolveComponent("notifications-general")!
  const _component_notifications_notifications = _resolveComponent("notifications-notifications")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    title: "Notification settings",
    size: "large",
    "bg-shade": "",
    "menu-title": _ctx.menuTitle
  }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    before: _withCtx(() => [
      _renderSlot(_ctx.$slots, "before")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_notifications_general, { class: "mb-4" }),
      _createVNode(_component_notifications_notifications, { class: "mb-4" })
    ]),
    _: 3
  }, 8, ["menu-title"]))
}