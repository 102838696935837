export const notificationSections = [
  {
    label: 'Balance',
    caption: 'Receive alerts when your credit balance is low to ensure your messaging remains uninterrupted.',
    icon: 'credit_card',
    opened: false,
    notificationsSms: true,
    notifications: [
      {
        name: 'balanceNotifications',
        emailValue: true,
        smsValue: false,
        label: 'Low balance notifications status',
        tooltip: 'You will receive a notification when balance falls below the value selected in the next row.',
      },
    ],
    checkboxes: [
      {
        type: 'select',
        value: '$2',
        options: ['$1', '$2', '$3', '$5', '$10', '$20', '$30', '$50', '$100', '$500', '$1,000'],
        label: 'Alert when balance falls below',
        tooltip: 'You will receive a notification when balance falls below the value selected in the next row.',
      },
      {
        type: 'tags',
        placeholderBtn: 'Add emails',
        value: [],
        label: 'Low balance alerts emails',
        tooltip: 'Send low balance notifications to this email.',
        placeholder: 'Enter email recipients to receive alerts',
      },
    ],
  },
  {
    label: 'Messenger',
    caption: 'Set up your notification preferences for new messages and calls.',
    icon: 'chat',
    opened: false,
    notifications: [
      {
        name: 'incomingSMS',
        emailValue: true,
        label: 'New incoming SMS',
        tooltip: 'You will receive a notification when you receive an SMS.',
      },
      {
        name: 'whatsAppMessage',
        emailValue: true,
        label: 'New WhatsApp message',
        tooltip: 'You will receive an email notification whenever a new WhatsApp message arrives',
      },
      {
        name: 'callForwarded',
        emailValue: true,
        label: 'New call forwarded',
        tooltip: 'You will receive an email notification after every forwarded call.',
      },
    ],
    checkboxes: [
      {
        type: 'toggle',
        label: 'Include recent chat history',
        value: true,
        tooltip: 'Email alert will include recentconversation history with thecontact that sent you a message.',
      },
      {
        type: 'toggle',
        label: 'Send email in HTML format',
        value: false,
        tooltip: 'When turned off, email will besent in plain text format.',
      },
      {
        type: 'tags',
        placeholderBtn: 'Add emails',
        value: [],
        label: 'Send alerts to these emails',
        tooltip: 'Send notifications to this email.',
        placeholder: 'Enter email recipients to receive alerts',
      },
    ],
  },
  {
    label: 'Tasks',
    caption: 'Manage instant notifications for incoming text messages.',
    icon: 'tmi-layers-tasks',
    opened: false,
    disabled: true,
    notifications: [
      {
        name: 'assignedToMe',
        emailValue: false,
        label: 'Task assigned to me',
        tooltip: 'You will receive an email when someone assigns a task to you.',
      },
      {
        name: 'assignedToMe',
        emailValue: false,
        label: 'New comment added',
        tooltip: 'You will receive an email when a new comment is added to the task assigned to you.',
      },
    ],
  },
  {
    label: 'Other notifications',
    caption: 'Customize your notification preferences for other updates in your account.',
    icon: 'notifications',
    opened: false,
    notifications: [
      {
        name: 'unsubscribed',
        emailValue: true,
        label: 'Recipient has unsubscribed',
        tooltip: 'You will receive notification if someone has unsubscribed from your contact list.',
      },
      {
        name: 'dailyReport',
        emailValue: false,
        label: 'Daily report',
        tooltip: 'You will receive a report with your messaging statistics log every day.',
      },
      {
        name: 'monthlyReport',
        emailValue: true,
        label: 'Monthly report',
        tooltip: 'You will receive a report with your messaging statistics log every month.',
      },
      {
        name: 'messageNotSent',
        emailValue: false,
        label: 'Scheduled message was not sent',
        tooltip: 'You will receive an email when scheduled message was not sent due to the low balance on your account.',
      },
      {
        name: 'forwardingFailed',
        emailValue: false,
        label: 'Call forwarding failed',
        tooltip: 'Someone tried to call your virtual number, but the call was not forwarded to you, because the call forwarding feature was not enabled.',
      },
      {
        name: 'importCompleted',
        emailValue: true,
        label: 'Import session completed',
        tooltip: 'You will receive an email when import session is completed.',
      },
      {
        name: 'onboardingEmails',
        emailValue: true,
        label: 'Onboarding emails',
        tooltip: 'You will receive several emails that will help you to learn more about Textmagic after your signup.',
      },
      {
        name: 'cancellationWarnings',
        emailValue: true,
        label: 'Number cancellation warnings',
        tooltip: 'You will receive these notifications if Auto-recharge is turned off, your balance is low and your number will renew soon. This will help you to keep your dedicated numbers from behind canceled due to low balance.',
      },
      {
        name: 'promotion',
        emailValue: true,
        label: 'Textmagic promotions',
        tooltip: 'Receive great offers from Textmagic.',
      },
    ],
  },
]
