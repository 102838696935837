import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-610a4fe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "tooltip-wrap" }
const _hoisted_3 = { class: "text-center lh-20" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "tooltip-wrap" }
const _hoisted_6 = { class: "text-center lh-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type === 'toggle')
      ? (_openBlock(), _createBlock(_component_tm_field, {
          key: 0,
          modelValue: _ctx.typeValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.typeValue) = $event)),
          type: "toggle"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_tm_icon, {
                name: "tmi-help-outline",
                class: "light--text pointer",
                size: "small"
              }),
              _createVNode(_component_tm_tooltip, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.tooltip), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : (_openBlock(), _createBlock(_component_tm_form_line, { key: 1 }, {
          label: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.label), 1),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_tm_icon, {
                  name: "tmi-help-outline",
                  class: "light--text pointer",
                  size: "small"
                }),
                _createVNode(_component_tm_tooltip, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.tooltip), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          default: _withCtx(() => [
            (_ctx.type === 'select')
              ? (_openBlock(), _createBlock(_component_tm_field, {
                  key: 0,
                  modelValue: _ctx.typeValue,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.typeValue) = $event)),
                  options: _ctx.options,
                  clearable: false,
                  type: "select",
                  class: "w120px"
                }, null, 8, ["modelValue", "options"]))
              : _createCommentVNode("", true),
            (_ctx.type === 'tags')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.showPlaceholderBtn)
                    ? (_openBlock(), _createBlock(_component_tm_button, {
                        key: 0,
                        "text-link": "",
                        class: "primary--text mt-1",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPlaceholderBtn = false))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_tm_icon, {
                            name: "add",
                            left: ""
                          }),
                          _createElementVNode("span", null, _toDisplayString(_ctx.placeholderBtn), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_tm_field, {
                        key: 1,
                        modelValue: _ctx.typeValue,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.typeValue) = $event)),
                        autofocus: "",
                        type: "editableBadges",
                        class: "max-w536px",
                        placeholder: _ctx.placeholder
                      }, null, 8, ["modelValue", "placeholder"]))
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
  ]))
}